import React from "react";
import { Tab } from "semantic-ui-react";
import Layout from "../components/layout";
import BodyClassName from "react-body-classname";
import SEO from "../components/seo";
import { navigate } from "gatsby-plugin-intl";

const TabsPage = () => {
	const onHeaderGeoLocationChange = (data) => {
		navigate(
			`/anuncios/mode/geo/lat/${data.lat}/long/${data.long}/geo_descripcion/${data.descripcion}/orden/distancia`
		);
	};

	return (
		<>
			<BodyClassName className="home">
				<Layout onHeaderGeoLocationChange={onHeaderGeoLocationChange}>
					<SEO title="Tabs" />
					<main className="page">
						<Tab
							menu={{ secondary: true, pointing: true }}
							panes={[
								{
									menuItem: "Tab 1",
									render: () => <Tab.Pane attached={false}>Tab 1 Content</Tab.Pane>,
								},
								{
									menuItem: "Tab 2",
									render: () => <Tab.Pane attached={false}>Tab 2 Content</Tab.Pane>,
								},
								{
									menuItem: "Tab 3",
									render: () => <Tab.Pane attached={false}>Tab 3 Content</Tab.Pane>,
								},
							]}
						/>
					</main>
				</Layout>
			</BodyClassName>
		</>
	);
};

export default TabsPage;
